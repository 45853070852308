<template>
  <div class="administrator">
    <el-form>
      <el-form-item>
        <el-button type="primary" @click="dialogVisible = true">
          <i class="el-icon-plus" /> 新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      :data="administratorTable"
      border
      max-height="680"
      :header-cell-style="cellStyle"
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID" width="100" align="center" />
      <el-table-column label="头像" align="center">
        <template v-slot="{ row }">
          <el-image :src="row.headImgUrl" fit="contain" />
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="账号" align="center" />
      <el-table-column prop="grade" label="角色" align="center">
        <template v-slot="{ row }">
          <div v-if="row.grade === 'THE_ADMINISTRATOR'">管理员</div>
          <div v-else-if="row.grade === 'TECHNICAL_PERSONNEL'">技术人员</div>
          <div v-else>运营人员</div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" />
      <el-table-column prop="createTime" label="最后登录时间" align="center" />
      <el-table-column prop="state" label="状态" align="center">
        <template v-slot="{ row }">
          <el-switch
            v-model="row.state"
            active-text="禁用"
            inactive-text="启用"
            @change="isStop(row.id)"
            :width="60"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" style="color: #65b471" @click="editClick(row)"
            >编辑</el-button
          >
          <span>|</span>
          <el-button type="text" style="color: #ff4444" @click="closeClick(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      ref="addVisible"
      title="新增管理员"
      :visible.sync="dialogVisible"
      width="25%"
      @close="handleClose"
    >
      <el-form
        ref="administratorObj"
        :model="administratorObj"
        :rules="administratorRules"
        label-width="80px"
      >
        <el-form-item label="账号" prop="phone">
          <el-input v-model="administratorObj.phone" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="角色名称" prop="type">
          <el-radio v-model="administratorObj.type" label="1">管理员</el-radio>
          <el-radio v-model="administratorObj.type" label="2"
            >技术人员</el-radio
          >
          <el-radio v-model="administratorObj.type" label="3"
            >运营人员</el-radio
          >
        </el-form-item>
        <el-form-item label="是否启用" prop="state">
          <el-radio v-model="administratorObj.state" :label="false"
            >启用</el-radio
          >
          <el-radio v-model="administratorObj.state" :label="true"
            >禁用</el-radio
          >
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAdministration,
  addAdministration,
  isStop,
  closeUser,
} from "@/api/administrator";

const defaultAdministratorObj = Object.freeze({
  phone: null,
  nickname: null,
  state: false, // 启用
  type: null,
});
export default {
  name: "Administrator",

  data() {
    return {
      administratorTable: [],
      cellStyle: {
        background: "#EBEAEA",
        color: "#151515",
      },
      loading: false,
      dialogVisible: false,
      administratorObj: { ...defaultAdministratorObj },
      administratorRules: {
        phone: [{ required: true, message: "请输入账号", trigger: "blur" }],
        type: [
          { required: true, message: "请选择角色名称", trigger: "change" },
        ],
      },
    };
  },

  created() {
    this.search();
  },

  methods: {
    //编辑
    editClick(row) {
      this.$router.push({
        name: "Information",
        params: { userId: row.id },
      });
    },
    closeClick(row) {
      this.$confirm(`确认删除${row.phone}吗 ?`, {
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await closeUser({ userId: row.id });

          if (res.success) {
            this.$messageSuccess(`${row.phone}删除成功`);

            this.search();
          }
        })
        .catch(() => {});
    },
    async search() {
      const { data: res } = await getAdministration();

      this.administratorTable = res.data;
    },

    handleClose() {
      Object.assign(this.administratorObj, defaultAdministratorObj);

      this.dialogVisible = false;
    },

    submitForm() {
      this.$refs.administratorObj.validate(async (valid) => {
        if (valid) {
          const { data: res } = await addAdministration(this.administratorObj);

          if (res.success) {
            this.$messageSuccess(res.message);

            this.search();
          }
          this.dialogVisible = false;
        }
      });
    },

    async isStop(val) {
      const { data: res } = await isStop({ id: val });

      if (res.success) {
        this.$messageSuccess(res.message);
      }
    },
  },
};
</script>

<style lang="less" scoped>

.el-image {
  width: 75px;
  height: 75px;
}
</style>
