import request from "@/utils/request"

// 查询管理员
export function getAdministration(data) {
  return request({
    url: "/user/findAll",
    method: "get",
    params: data
  })
}

// 新增管理员
export function addAdministration(data) {
  return request({
    url: "/user/saveMember",
    method: "post",
    params: data
  })
}

// 是否启用
export function isStop(data) {
  return request({
    url: "/user/stop",
    method: "post",
    params: data
  })
}

//根据id查询用户
export function getUserId(data) {
  return request({
    url: '/user/getById',
    method: 'get',
    params: data
  })
}

//保存编辑
export function editsSave(data) {
  return request({
    url: '/user/updateAdmin',
    method: 'put',
    data
  })
}

//删除管理用户
export function closeUser(data) {
  return request({
    url: '/user/deleteById',
    method: 'delete',
    params: data
  })
}